import * as React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CarCard from "../components/car-card"
import { useTranslation } from "react-i18next"

const SoldPage = ({ data, location }) => {
  const { cars } = data.allStrapiCar;
  const { t } = useTranslation();

  return (
    <Layout location={location}>
      <Seo
        title={`${t('Sold')}`}
        description={t('SoldMetaDescription')}
      />
      <div className="site-width page-title">
        <h1 className="">{t('SoldCars')}</h1>
      </div>
      <section class="c-block c-block--first l-wrap" style={{ paddingTop: '48px' }}>
        <div className="site-width">
          <div class="c-block__content c-block__content--cars">
            {cars.map(c => {
              return <>
                <CarCard
                  car={c.node}
                  key={c.node.slug}
                />
              </>
            })}
          </div>
        </div>
      </section>
    </Layout >
  )
}

export default SoldPage


export const query = graphql`
      query SoldCarsQuery($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
            node {
              ns
              data
              language
            }
          }
        }
        allStrapiCar(
          sort: {fields: sellingDate, order: DESC}
          filter: {sellingDate: {ne: null}, locale: {eq: $language}}
        ) {
          cars: edges {
            node {
              slug
              title
              mileage
              fuel_type {
                name
              }
              make {
                name
              }
              transmission {
                name
              }
              coverImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    `;